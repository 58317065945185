<template>
    <invoiceForm :is-new="false" />
  </template>
  
  <script>
  import invoiceForm from "./invoiceForm.vue";
  
  export default {
    components: { invoiceForm },
  };
  </script>
  
  <style></style>
  